interface IOSInfo {
  asReported: string;
  fullRelease: string;
  fullReleaseNumeric: number;
  majorRelease: string;
  majorReleaseNumeric: number;
  userAgent: string;
}

export const getIOSVersion = (): IOSInfo | null => {
  const userAgent = navigator.userAgent;

  if (userAgent.match(/ipad|iphone|ipod/i)) {
    const match = userAgent.match(/OS (\d+_\d+(_\d+)?)/i);

    if (match) {
      const asReported = match[0];
      const majorRelease = asReported.split('_')[0];
      const fullRelease = asReported.replace(/_/g, '.');
      const majorReleaseNumeric = parseFloat(majorRelease.replace('OS ', ''));
      const fullReleaseNumeric = parseFloat(
        asReported.replace('_', '.').replace('_', '').replace('OS ', '')
      );

      const iosInfo: IOSInfo = {
        userAgent,
        asReported,
        fullRelease,
        majorRelease,
        fullReleaseNumeric,
        majorReleaseNumeric,
      };

      return iosInfo;
    }
  }

  return null;
};
