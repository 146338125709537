export const ENV = {
  VITE_SENTRY_DSN: window.globalENV?.VITE_SENTRY_DSN,
  VITE_ENVIRONMENT: window.globalENV?.VITE_ENVIRONMENT,
  VITE_CITIZEN_URL: window.globalENV?.VITE_CITIZEN_URL,
  VITE_MIFINITY_URL: window.globalENV?.VITE_MIFINITY_URL,
  VITE_CASHIER_DOMAIN: window.globalENV?.VITE_CASHIER_DOMAIN,
  VITE_HOTJAR_VERSION: window.globalENV?.VITE_HOTJAR_VERSION,
  VITE_HOTJAR_SITE_ID: window.globalENV?.VITE_HOTJAR_SITE_ID,
  VITE_PAYMENT_IQ_URL: window.globalENV?.VITE_PAYMENT_IQ_URL,
  VITE_PRAXIS_HPF_URL: window.globalENV?.VITE_PRAXIS_HPF_URL,
  VITE_CASHIER_API_URL: window.globalENV?.VITE_CASHIER_API_URL,
  VITE_API_LOG_ENABLED: window.globalENV?.VITE_API_LOG_ENABLED,
  VITE_PRAXIS_HPF_HASH: window.globalENV?.VITE_PRAXIS_HPF_HASH,
  VITE_SC_AP_DEBUG_MODE: window.globalENV?.VITE_SC_AP_DEBUG_MODE,
  VITE_NUVEI_ENVIRONMENT: window.globalENV?.VITE_NUVEI_ENVIRONMENT,
  VITE_SENTRY_ENVIRONMENT: window.globalENV?.VITE_SENTRY_ENVIRONMENT,
  VITE_USER_ACTIVITY_API_URL: window.globalENV?.VITE_USER_ACTIVITY_API_URL,
  VITE_CASHIER_RELEASE_VERSION: window.globalENV?.VITE_CASHIER_RELEASE_VERSION,
  VITE_API_ACTIVITY_LOG_ENABLED:
    window.globalENV?.VITE_API_ACTIVITY_LOG_ENABLED,
  VITE_STATISTIC_POST_MESSAGES_ENABLED:
    window.globalENV?.VITE_STATISTIC_POST_MESSAGES_ENABLED,
};
