import { additionalFieldErrorMessages } from '@core/constants/additionalFieldErrorMessages';
import { type Field } from '@core/types';
import {
  formattedFieldValue,
  isEmpty,
  isUserAdult,
  isValidDateOfBirth,
} from '@core/utils';
import { createAction, createReducer, current } from '@reduxjs/toolkit';

interface IAdditionalFieldsSettings {
  additionalFieldsErrors: Record<string, string>;
  additionalFieldValues: null | Record<string, string>;
  collapsibleFields: Field[];
  collapsibleFieldsBlockOpenedCount: number;
  dateError: string;
  hasRequiredCheckbox?: boolean;
  isAdditionalFieldValuesInvalid: boolean;
  isCollapsibleFieldsOpened: boolean;
  isRequiredCheckboxChecked?: boolean;
  notCollapsibleFields: Field[];
  validationErrorCount: number;
}

const initialState: IAdditionalFieldsSettings = {
  dateError: '',
  collapsibleFields: [],
  validationErrorCount: 0,
  notCollapsibleFields: [],
  hasRequiredCheckbox: false,
  additionalFieldsErrors: {},
  additionalFieldValues: null,
  isRequiredCheckboxChecked: false,
  isCollapsibleFieldsOpened: false,
  collapsibleFieldsBlockOpenedCount: 0,
  isAdditionalFieldValuesInvalid: false,
};

export const setAdditionalFieldsData = createAction<Field[]>(
  'appAdditionalFieldsSettings/setAdditionalFieldsData'
);

export const setAdditionalFieldValues = createAction<null | Record<
  string,
  string
>>('appAdditionalFieldsSettings/setAdditionalFieldValues');

export const setIsAdditionalFieldValuesInvalid = createAction<boolean>(
  'appAdditionalFieldsSettings/setIsAdditionalFieldValuesInvalid'
);

export const setAdditionalFieldsErrors = createAction<Record<string, string>>(
  'appAdditionalFieldsSettings/setAdditionalFieldsErrors'
);

export const setRequiredCheckboxValue = createAction<boolean>(
  'appAdditionalFieldsSettings/setRequiredCheckboxValue'
);

export const setCollapsibleFieldsBlockOpenedCount = createAction<number>(
  'appAdditionalFieldsSettings/setCollapsibleFieldsBlockOpenedCount'
);

export const resetAdditionalFields = createAction(
  'appAdditionalFieldsSettings/resetAdditionalFields'
);

export const appAdditionalFieldsSettings = createReducer(
  initialState,
  (builder) => {
    builder.addCase(setAdditionalFieldsData, (state, action) => {
      if (action.payload.length === 0) {
        state.collapsibleFields = [];
        state.notCollapsibleFields = [];
        return;
      }

      const notCollapsible: Field[] = [];
      const collapsible: Field[] = [];
      const allFields: Field[] = [];
      let validationErrorCount = 0;
      let collapsibleFieldsBlockOpenedCount = 0;

      action.payload.forEach((field) => {
        if (field.type === 'DATE') {
          const [year, month, day] = field.value
            ? field.value.split('-')
            : ['', '', ''];

          const transformedFields = [
            {
              ...field,
              value: day,
              order: field.order,
              name: `${field.name}-DAY`,
            },
            {
              ...field,
              value: month,
              order: field.order,
              name: `${field.name}-MONTH`,
            },
            {
              ...field,
              value: year,
              order: field.order,
              name: `${field.name}-YEAR`,
            },
          ];
          transformedFields.forEach((f) =>
            (f.alwaysVisible ? notCollapsible : collapsible).push(f)
          );
          allFields.push(...transformedFields);
        } else {
          (field.alwaysVisible ? notCollapsible : collapsible).push(field);
          allFields.push(field);
        }

        if (field.required && field.type !== 'DATE' && !field.value) {
          state.isAdditionalFieldValuesInvalid = true;
          state.additionalFieldsErrors[field.name] = 'error.fieldRequired';

          if (field.readOnly) {
            state.additionalFieldsErrors[field.name] =
              '$t(error.fieldRequired). $t(error.contactWithCustomerSupport)';
          } else {
            state.additionalFieldsErrors[field.name] = 'error.fieldRequired';
          }

          validationErrorCount += 1;

          if (!field.alwaysVisible) {
            state.isCollapsibleFieldsOpened = true;
            collapsibleFieldsBlockOpenedCount += 1;
          }
        }

        if (field.type === 'DATE') {
          const [year, month, day] = field.value
            ? field.value.split('-')
            : ['', '', ''];

          if (!isValidDateOfBirth(`${day}-${month}-${year}`)) {
            state.dateError = 'error.invalidDateOfBirth';
            state.isAdditionalFieldValuesInvalid = true;
            state.isCollapsibleFieldsOpened = true;
            validationErrorCount += 1;
            collapsibleFieldsBlockOpenedCount += 1;
          } else if (!isUserAdult(`${day}-${month}-${year}`)) {
            state.dateError = 'error.userUnderaged';
            state.isAdditionalFieldValuesInvalid = true;
            state.isCollapsibleFieldsOpened = true;
            validationErrorCount += 1;
            collapsibleFieldsBlockOpenedCount += 1;
          } else {
            state.dateError = '';
          }
        }

        if (
          field.type === 'CHECKBOX' &&
          field.required &&
          field.value === 'false'
        ) {
          state.hasRequiredCheckbox = true;
          state.isRequiredCheckboxChecked = false;
          state.isAdditionalFieldValuesInvalid = true;
        }

        if (
          field.regex &&
          field.value &&
          !new RegExp(field.regex.slice(1, -1)).test(field.value)
        ) {
          state.additionalFieldsErrors[field.name] = field.readOnly
            ? 'error.contactWithCustomerSupport'
            : additionalFieldErrorMessages[field.name] ||
              'error.wrongValueField';
          state.isAdditionalFieldValuesInvalid = true;
          validationErrorCount += 1;
          if (!field.alwaysVisible) {
            state.isCollapsibleFieldsOpened = true;
            collapsibleFieldsBlockOpenedCount += 1;
          }
        }

        if (!field.value) {
          state.isCollapsibleFieldsOpened = true;
        }
      });

      state.collapsibleFields = collapsible.sort((a, b) => a.order - b.order);

      state.notCollapsibleFields = notCollapsible.sort(
        (a, b) => a.order - b.order
      );

      state.additionalFieldValues = transformToValues(allFields);
      state.validationErrorCount = validationErrorCount;
      state.collapsibleFieldsBlockOpenedCount =
        collapsibleFieldsBlockOpenedCount;
    });
    builder.addCase(setAdditionalFieldValues, (state, action) => {
      if (action.payload === null) {
        state.additionalFieldValues = null;
      } else {
        state.additionalFieldValues = action.payload;

        const currentValidationErrorCount = current(state).validationErrorCount;

        const fullDate = Object.entries(action.payload)
          .filter(([key]) => /-(DAY|MONTH|YEAR)$/.test(key))
          .sort(([a], [b]) => a.localeCompare(b)) // Ensures order DAY-MONTH-YEAR
          .map(([_, value]) => value)
          .join('-');

        if (fullDate) {
          if (!isValidDateOfBirth(fullDate)) {
            state.dateError = 'error.invalidDateOfBirth';
            state.validationErrorCount = currentValidationErrorCount + 1;
            return;
          } else {
            state.dateError = '';
          }

          if (!isUserAdult(fullDate)) {
            state.dateError = 'error.userUnderaged';
            state.validationErrorCount = currentValidationErrorCount + 1;
            return;
          } else {
            state.dateError = '';
          }
        }
      }
    });
    builder.addCase(setIsAdditionalFieldValuesInvalid, (state, action) => {
      state.isAdditionalFieldValuesInvalid = action.payload;
    });
    builder.addCase(resetAdditionalFields, () => {
      return initialState;
    });
    builder.addCase(setRequiredCheckboxValue, (state, action) => {
      state.isRequiredCheckboxChecked = action.payload;
    });
    builder.addCase(setCollapsibleFieldsBlockOpenedCount, (state, action) => {
      state.collapsibleFieldsBlockOpenedCount = action.payload;
    });
    builder.addCase(setAdditionalFieldsErrors, (state, action) => {
      state.additionalFieldsErrors = action.payload;

      const currentValidationErrorCount = current(state).validationErrorCount;

      state.validationErrorCount = currentValidationErrorCount + 1;

      if (isEmpty(action.payload)) {
        state.isAdditionalFieldValuesInvalid = state.hasRequiredCheckbox
          ? !state.isRequiredCheckboxChecked || Boolean(state.dateError)
          : Boolean(state.dateError);
      } else {
        state.isAdditionalFieldValuesInvalid = true;
      }
    });
  }
);

const transformToValues = (data: Field[]) => {
  return data.reduce<Record<string, string>>((acc, { name, value }) => {
    acc[name] = formattedFieldValue(name, value);

    return acc;
  }, {});
};
