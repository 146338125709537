import { METHODS, PROVIDERS } from '@core/constants';
import { type IEmbeddedPageProperties } from '@core/types';
import { omit } from '@core/utils';

export interface IConfirmationPageState {
  accountHolder?: string;
  amount?: string;
  bic?: string;
  currency?: string;
  entity?: string;
  expirationDate?: string;
  iban?: string;
  paycashReferenceId?: string;
  payToken?: string;
  provider?: string;
  qr?: string;
  referenceId?: string;
  transactionId?: null | string;
}

const { EUPAGO, PAYCASH, MICROPAYMENT } = PROVIDERS;
const { MULTIBANCO } = METHODS;

const formatBrlCurrency = (currency: string) =>
  currency?.toLowerCase() === 'brl' ? 'R$' : currency;

export const getConfirmationPageState = (
  provider: string,
  properties: Camelize<IEmbeddedPageProperties>,
  transactionUuid: string,
  method?: string
) => {
  const state: IConfirmationPageState = {};

  if (provider === PAYCASH) {
    state.transactionId = transactionUuid;
    state.paycashReferenceId = properties.paycashReferenceId;
    return state;
  }
  if (provider === MICROPAYMENT) {
    state.referenceId = properties.payToken;
    state.amount = properties.amount;
    state.iban = properties.iban;
    state.bic = properties.bic;
    state.accountHolder = properties?.accountHolder;
    state.transactionId = transactionUuid;
    state.expirationDate = properties.expirationDate;
    state.currency = formatBrlCurrency(properties.userCurrency ?? '');

    return state;
  }
  if (provider === EUPAGO) {
    state.transactionId = transactionUuid;
    state.amount = properties.amount;
    if (method === MULTIBANCO) {
      state.entity = properties.entity;
    }
    state.referenceId = properties.referenceId;
    state.currency = formatBrlCurrency(properties.userCurrency ?? '');

    return state;
  }
  if (properties?.qr) {
    state.amount = properties.amount;
    state.qr = properties.qr;
    state.provider = provider;
    state.currency = formatBrlCurrency(properties.userCurrency ?? '');
    state.transactionId = transactionUuid;

    return state;
  }

  state.transactionId = transactionUuid;

  return { ...state, ...omit(properties, ['showConfirmationPage']) };
};
