import { lazy, type ReactElement, StrictMode } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { Suspendable } from '@core/components/Suspendable';
import { initializeHotjar } from '@core/loggers/Hotjar';
import CashierSentry, { initializeSentry } from '@core/loggers/Sentry';
import { setupStore } from '@core/store';
import { createRoot } from 'react-dom/client';

import '@source/index.css';
const store = setupStore();

const cashierVersion =
  new URL(location.href).searchParams.get('version') ?? 'v1';
const direction = new URL(location.href).searchParams.get('dir');

const CashierV1 = Suspendable(lazy(() => import('./v1')));
const CashierV2 = Suspendable(lazy(() => import('./v2')));

if (cashierVersion === 'v2' && direction) {
  document.documentElement.setAttribute('dir', direction);
}

// Initialize Hotjar
initializeHotjar();

// Initialize Sentry
initializeSentry();

const cashierModule: Record<string, ReactElement> = {
  v1: <CashierV1 />,
  v2: <CashierV2 />,
};

const domNode = document.getElementById('root') as HTMLElement;

createRoot(domNode, {
  onCaughtError: CashierSentry.reactErrorHandler(),
  onRecoverableError: CashierSentry.reactErrorHandler(),
  onUncaughtError: CashierSentry.reactErrorHandler((error, errorInfo) => {
    console.warn('Uncaught error', error, errorInfo.componentStack);
  }),
}).render(
  <StrictMode>
    <ReduxProvider store={store}>
      {cashierModule[cashierVersion] ?? cashierModule.v1}
    </ReduxProvider>
  </StrictMode>
);
