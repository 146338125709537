import { createAction, createReducer } from '@reduxjs/toolkit';

export enum CashierAppVersion {
  v1 = 'v1',
  v2 = 'v2',
}

export type AppVersion = `${CashierAppVersion}`;

interface IConfigSettings {
  allowDecimals: boolean;
  fontFamily: FontFamily;
  liveChat: boolean;
  locale?: string;
  mode: ThemeMode;
  presetColorV1: PresetColor;
  presetColorV2: PresetColor;
  themeDirection: ThemeDirection;
  version: AppVersion;
}

const initialState: IConfigSettings = {
  version: 'v1',
  mode: 'light',
  liveChat: false,
  allowDecimals: false,
  themeDirection: 'ltr',
  presetColorV2: 'light',
  presetColorV1: 'default',
  fontFamily: `'Poppins', sans-serif`,
};

export const setAppConfigData = createAction<Partial<IConfigSettings>>(
  'appConfigSettings/setAppConfigData'
);

export const setThemeMode = createAction<ThemeMode>(
  'appConfigSettings/setThemeMode'
);

export const appConfigSettings = createReducer(initialState, (builder) => {
  builder.addCase(setAppConfigData, (state, action) => {
    const {
      version,
      liveChat,
      fontFamily,
      presetColorV1,
      presetColorV2,
      allowDecimals,
      themeDirection,
    } = action.payload;

    const isValidVersion = [
      CashierAppVersion.v1,
      CashierAppVersion.v2,
    ].includes(version as AppVersion);

    if (isValidVersion) {
      state.version =
        version === CashierAppVersion.v1
          ? CashierAppVersion.v1
          : CashierAppVersion.v2;

      if (version === CashierAppVersion.v1) {
        state.mode =
          presetColorV1 === 'default' || presetColorV1?.startsWith('light')
            ? 'light'
            : 'dark';
        state.presetColorV1 = presetColorV1 ?? 'default';
      } else if (version === CashierAppVersion.v2) {
        state.liveChat = liveChat ?? false;
        state.mode = presetColorV2 === 'light' ? 'light' : 'dark';
        state.presetColorV2 = presetColorV2 ?? 'light';
      }
    } else {
      // Fallback to default values for invalid versions
      state.version = CashierAppVersion.v1;
      state.mode = 'light';
      state.presetColorV1 = presetColorV1 ?? 'default';
    }

    state.themeDirection = themeDirection ?? 'ltr';
    state.fontFamily = fontFamily ?? `'Poppins', sans-serif`;
    state.allowDecimals = allowDecimals ?? false;
  });
  builder.addCase(setThemeMode, (state, action) => {
    state.mode = action.payload ?? 'light';
    state.presetColorV2 = action.payload ?? 'light';
  });
});
