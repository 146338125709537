export function detectSafari(): boolean {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return (
    userAgent.includes('safari') &&
    !userAgent.includes('chrome') &&
    !userAgent.includes('crios') &&
    !userAgent.includes('fxios') &&
    !userAgent.includes('opera')
  );
}
