import { API, ENV } from '@core/constants';
import { type RootState } from '@core/store';
import { parseSearchParams } from '@core/utils';
import { IEvent } from '@core/utils/buildCashierEvent';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const activityLogApi = createApi({
  reducerPath: 'activityApi',
  invalidationBehavior: 'immediately',
  baseQuery: fetchBaseQuery({
    baseUrl: ENV.VITE_USER_ACTIVITY_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).currentSettings.jwt ?? '';
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    sendActivityLog: build.mutation<unknown, IEvent>({
      query: (payload) => ({
        method: 'POST',
        url: API.ACTIVITY,
        body: {
          ...payload,
          timestamp: new Date().getTime(),
          metadata: {
            ...payload.metadata,
            session_id: parseSearchParams(window.location.search, 'sid'),
          },
        },
      }),
    }),
  }),
});

export const { useSendActivityLogMutation } = activityLogApi;
