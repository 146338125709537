import { cashierApi } from '@core/api';
import { ENV } from '@core/constants';
import CashierSentry from '@core/loggers/Sentry';
import rootReducer from '@core/store/reducers';
import { configureStore, Middleware } from '@reduxjs/toolkit';

const sentryReduxEnhancer = CashierSentry.createReduxEnhancer({
  configureScopeWithState: (_, state) => {
    return state;
  },
});

const middlewareList: Middleware[] = [cashierApi.middleware];

export const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    devTools: ENV.VITE_ENVIRONMENT === 'dev',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middlewareList),
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers({
        autoBatch: { type: 'tick' },
      }).concat(sentryReduxEnhancer);
    },
  });

type AppDispatch = AppStore['dispatch'];
type AppStore = ReturnType<typeof setupStore>;
type RootState = ReturnType<typeof rootReducer>;

export type { AppStore, RootState, AppDispatch };
