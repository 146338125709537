import { createAction, createReducer } from '@reduxjs/toolkit';

interface ICryptoAppSessionSettings {
  isWalletConnectShown: boolean;
}

const initialState: ICryptoAppSessionSettings = {
  isWalletConnectShown: false,
};

export const setIsWalletConnectShown = createAction<boolean>(
  'cryptoAppSessionSetting/setIsWalletConnectShown'
);

export const cryptoAppSessionSetting = createReducer(
  initialState,
  (builder) => {
    builder.addCase(setIsWalletConnectShown, (state, action) => {
      state.isWalletConnectShown = action.payload;
    });
  }
);
