import { createAction, createReducer } from '@reduxjs/toolkit';

interface IPraxisHpfSettings {
  cardExpires: null | string;
  cardHolder: null | string;
  cardNumber: null | string;
  cardType: null | string;
  isCardDataProcessingLoading: boolean;
  isCardValid: boolean;
  isFieldsWasTouched: boolean;
  isSessionReady: boolean;
  praxisHpfToken: null | string;
}

const initialState: IPraxisHpfSettings = {
  cardType: '',
  cardHolder: '',
  cardNumber: '',
  cardExpires: '',
  isCardValid: false,
  praxisHpfToken: '',
  isSessionReady: false,
  isFieldsWasTouched: false,
  isCardDataProcessingLoading: false,
};

export const setPraxisHpfCardInfoData = createAction<{
  cardExpires: string;
  cardHolder: string;
  cardNumber: string;
  cardType: string;
}>('providerSettings/setPraxisHpfCardInfoData');

export const setIsCardValid = createAction<boolean>(
  'praxisHpfSettings/setIsCardValid'
);

export const setPraxisHpfToken = createAction<null | string>(
  'praxisHpfSettings/setPraxisHpfToken'
);

export const setInitSessionReady = createAction<boolean>(
  'praxisHpfSettings/setInitSessionReady'
);

export const setIsCardDataProcessingLoading = createAction<boolean>(
  'praxisHpfSettings/setIsCardDataProcessingLoading'
);

export const setIsFieldsWasTouched = createAction<boolean>(
  'praxisHpfSettings/setIsFieldsWasTouched'
);

export const praxisHpfSettings = createReducer(initialState, (builder) => {
  builder.addCase(setPraxisHpfCardInfoData, (state, action) => {
    state.cardNumber = action.payload.cardNumber;
    state.cardType = action.payload.cardType;
    state.cardExpires = action.payload.cardExpires;
    state.cardHolder = action.payload.cardHolder;
  });
  builder.addCase(setIsCardValid, (state, action) => {
    state.isCardValid = action.payload;
  });
  builder.addCase(setInitSessionReady, (state, action) => {
    state.isSessionReady = action.payload;
  });
  builder.addCase(setPraxisHpfToken, (state, action) => {
    state.praxisHpfToken = action.payload;
  });
  builder.addCase(setIsCardDataProcessingLoading, (state, action) => {
    state.isCardDataProcessingLoading = action.payload;
  });
  builder.addCase(setIsFieldsWasTouched, (state, action) => {
    state.isFieldsWasTouched = action.payload;
  });
});
