import { ENV } from '@core/constants/env';
import { AppVersion } from '@core/store/reducers/appConfigSettings';
import { parseSearchParams } from '@core/utils/parseSearchParams';

const cashier_version = parseSearchParams(
  window.location.search,
  'version'
) as AppVersion;
const theme = parseSearchParams(window.location.search, 'theme') as PresetColor;
const app_version = ENV.VITE_CASHIER_RELEASE_VERSION ?? '1';

export const authInitEvent = {
  type: 'TRACKER',
  action: 'AUTH_INIT',
  label: 'Cashier auth',
  category: 'session.auth',
  source: {
    id: 'self',
    location: 'status.html',
  },
  metadata: {
    theme,
    app_version,
    cashier_version,
  },
};

export const authSuccessEvent = {
  type: 'TRACKER',
  action: 'AUTH_SUCCESS',
  category: 'session.auth',
  label: 'Cashier auth success',
  source: {
    id: 'self',
    location: 'status.html',
  },
  metadata: {
    theme,
    app_version,
    cashier_version,
  },
};

export const authFailedEvent = {
  type: 'TRACKER',
  action: 'AUTH_FAILED',
  category: 'session.auth',
  label: 'Cashier auth failed',
  source: {
    id: 'self',
    location: 'status.html',
  },
  metadata: {
    theme,
    app_version,
    cashier_version,
  },
};

export const cashierClosedEvent = {
  type: 'TRACKER',
  label: 'Cashier closed',
  action: 'CASHIER_CLOSED',
  category: 'cashier.window',
  source: {
    id: 'self',
    location: 'status.html',
  },
  metadata: {
    theme,
    app_version,
    cashier_version,
  },
};
