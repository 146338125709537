const fieldFormats: Record<string, (value: string) => string> = {
  IBAN: (value) => value?.replace(/\s+/g, '') ?? '',
  TERMS_AND_CONDITIONS_AGREEMENT: (value) => String(value),
};

export const formattedFieldValue = (
  fieldName: string,
  fieldValue: string
): string => {
  const formatter = fieldFormats[fieldName];
  return formatter ? formatter(fieldValue) : fieldValue;
};
