import { get } from '@core/utils/lodash';
import { type ErrorEvent, type EventHint } from '@sentry/react';

const rejectErrorMessages: Array<RegExp> = [
  /Failed to fetch dynamically imported module/i,
  /Element type is invalid\. Received a promise that resolves to: undefined\./,
  /React ErrorBoundary Error: Element type is invalid\. Received a promise that resolves to: undefined\. Lazy element type must resolve to a class or function\./,
  /Error: Element type is invalid\. Received a promise that resolves to: undefined\./,
  /Lazy element type must resolve to a class or function\./,
  /Cannot read properties of undefined \(reading 'default'\)/,
  /Lazy/i,
  /dynamically imported/i,
  /null is not an object \(evaluating 'window.localStorage.getItem'\)/,
  /null is not an object \(evaluating 'window.localStorage.removeItem'\)/,
  /Member PerformanceObserverInit.entryTypes is required and must be an instance of sequence/,
  /Cannot redefine property: googletag/i,
  /Cannot read properties of undefined \(reading 'site_id'\)/,
  /null is not an object \(evaluating 'iframe.contentWindow.postMessage'\)/,
  /Non-Error exception captured/,
  /Non-Error promise rejection captured/,
  /Object captured as promise rejection with keys:/i,
  /ResizeObserver loop completed with undelivered notifications\./,
];

export const handleRejectSentryErrors = (
  event: ErrorEvent,
  hint: EventHint
) => {
  const targetOriginalException = get(
    hint,
    'originalException.message',
    ''
  ) as string;

  const targetContextException = get(
    hint,
    'captureContext.contexts.react.componentStack',
    ''
  ) as string;

  if (
    rejectErrorMessages.some(
      (errorMessage) =>
        errorMessage.exec(targetOriginalException) ??
        errorMessage.exec(targetContextException)
    )
  ) {
    return null;
  }

  return event;
};
