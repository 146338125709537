import { ENV } from '@core/constants';
import Hotjar from '@hotjar/browser';

export const initializeHotjar = () => {
  const siteId = ENV.VITE_HOTJAR_SITE_ID;
  const hotjarVersion = ENV.VITE_HOTJAR_VERSION;

  if (ENV.VITE_ENVIRONMENT === 'prod') {
    Hotjar.init(siteId, hotjarVersion, {
      nonce: 'rAnDoM',
    });
  }
};
