export const additionalFieldErrorMessages: Record<string, string> = {
  CITY: 'error.invalidCity',
  EMAIL: 'error.invalidEmail',
  PHONE: 'error.invalidPhone',
  ZIP: 'error.invalidZipCode',
  ADDRESS: 'error.invalidAddress',
  POSTCODE: 'error.invalidPostcode',
  LAST_NAME: 'error.invalidLastName',
  FIRST_NAME: 'error.invalidFirstName',
  WALLET_ADDRESS: 'error.wrongCryptoAddressValueField',
};
