import { applePayMinVersionSupport, METHODS } from '@core/constants';
import { IPaymentMethodResponse, PaymentMethod } from '@core/types';
import { detectSafari, detectSamsung, getIOSVersion } from '@core/utils';

export const filterPaymentMethods = (
  method: IPaymentMethodResponse | PaymentMethod
) => {
  const isSafari = detectSafari();
  const isSamsung = detectSamsung();
  const iOSVersion = getIOSVersion()?.majorReleaseNumeric ?? 0;
  const isMobile = window.matchMedia('(max-width: 640px)').matches;

  const hideApplePay = isMobile
    ? Number(iOSVersion) >= applePayMinVersionSupport ||
      method.method !== METHODS.APPLEPAY
    : method.method !== METHODS.APPLEPAY;

  const hideSamsungPay = method.method !== METHODS.SAMSUNGPAY;

  const shouldIncludeMethod =
    (isSafari || hideApplePay) && (isSamsung || hideSamsungPay);

  return shouldIncludeMethod;
};
