export const API = Object.freeze({
  PAYMENT_METHODS: '/methods',
  ACTIVITY: '/activity/action',
  AUTHORIZE: '/auth/:sessionId',
  GET_SID: '/auth/:uuid/session',
  PAYMENT_METHOD_DETAILS: '/methods/details',
  ANOTHER_DEPOSIT: '/cashier/another-deposit',
  LOGGING: {
    LOG: '/statistic/log',
    METRIC: '/statistic/metric',
  },
  INTEGRATION: {
    KYC: {
      PROCESSING: '/processing-data',
    },
  },
  PAYMENT_INSTRUMENTS: {
    REMOVE_PAYMENT_INSTRUMENT: '/payment-instruments/:id',
  },
  CONFIG: {
    TRANSLATION: '/translations',
    TRANSLATION_BY_LOCALE: '/translations/:locale',
  },
  VERIFICATION: {
    RESEND_CODE: '/verification/resend-code',
    VERIFY_CODE: '/verification/verify-code',
  },
  PAYMENT: {
    ROOT: '/payment',
    PROCEED: '/payment/process/:uuid',
    VIRTUAL_PAYMENT: '/virtual/payment',
    TRANSACTION_DETAILS: '/status/:uuid',
  },
});
