const isEmpty = <T>(value: null | T | undefined): boolean => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  return false;
};

const omit = <T, K extends keyof T>(object: T, keys: K[]): Omit<T, K> => {
  return Object.fromEntries(
    Object.entries(object as Record<string, unknown>).filter(
      ([key]) => !keys.includes(key as K)
    )
  ) as Omit<T, K>;
};

const delay = <T extends unknown[]>(
  fn: (...args: T) => void,
  timer: number,
  ...args: T
): NodeJS.Timeout =>
  setTimeout(() => {
    fn(...args);
  }, timer);

const get = <T, K extends keyof T>(
  obj: T,
  path: string,
  defaultValue: string = ''
): unknown => {
  return path
    .split('.')
    .reduce<unknown>(
      (acc, part) =>
        acc && typeof acc === 'object' && acc !== null && part in acc
          ? (acc as T)[part as K]
          : defaultValue,
      obj
    );
};

export { get, omit, delay, isEmpty };
