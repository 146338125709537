export const getCurrentDate = (): string => {
  return new Intl.DateTimeFormat('en-GB', {
    hour12: false,
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    timeZone: 'UTC',
    month: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
    .format(new Date())
    .replace(',', '');
};

export function formattedDate(date: null | number, timeZone?: string): string {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'short',
    timeStyle: 'medium',
    ...(timeZone ? { timeZone } : { timeZone: 'GMT' }),
  }).format(date);
}

export const isValidDateOfBirth = (date: string): boolean => {
  const [day, month, year] = date.split('-').map(Number);
  const parsedDate = new Date(year, month - 1, day);

  if (
    parsedDate.getFullYear() !== year ||
    parsedDate.getMonth() !== month - 1 ||
    parsedDate.getDate() !== day
  ) {
    return false;
  }

  const minDate = new Date('1900-01-01');
  const maxDate = new Date();

  return parsedDate >= minDate && parsedDate <= maxDate;
};

export const isUserAdult = (date: string): boolean => {
  const [day, month, year] = date.split('-').map(Number);
  const birthDate = new Date(year, month - 1, day);

  if (isNaN(birthDate.getTime()) || !isValidDateOfBirth(date)) {
    return false;
  }

  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age >= 18 && age <= 100;
};
