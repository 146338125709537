export const normalizeAdditionalFieldValues = (
  data: Record<string, unknown>
) => {
  const result: Record<string, unknown> = {};
  const dateGroups: Record<string, Record<string, string>> = {};

  Object.entries(data).forEach(([key, value]) => {
    const match = key.match(/^(.*)-(DAY|MONTH|YEAR)$/);
    if (match) {
      const prefix = match[1];
      const part = match[2]; // "DAY", "MONTH", or "YEAR"
      if (!dateGroups[prefix]) {
        dateGroups[prefix] = {};
      }
      dateGroups[prefix][part] = value as string;
    } else {
      result[key] = value;
    }
  });

  // Merge the date parts into a single key
  Object.entries(dateGroups).forEach(([prefix, parts]) => {
    // Ensure all parts exist before merging
    if (parts.YEAR && parts.MONTH && parts.DAY) {
      result[prefix] =
        `${parts.YEAR}-${parts.MONTH.padStart(2, '0')}-${parts.DAY.padStart(2, '0')}`;
    }
  });

  return result;
};
