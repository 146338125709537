export const HOTJAR_EVENT = {
  V1_DEPOSIT_CLICK: 'v1_deposit_click',
  V2_DEPOSIT_CLICK: 'v2_deposit_click',
  V1_CASHIER_OPENED: 'v1_cashier_opened',
  V2_CASHIER_OPENED: 'v2_cashier_opened',
  V1_PAYMENT_FAILED: 'v1_payment_failed',
  V2_PAYMENT_FAILED: 'v2_payment_failed',
  V1_PAYMENT_SUCCESS: 'v1_payment_success',
  V2_PAYMENT_SUCCESS: 'v2_payment_success',
  V1_MAIN_PAGE_LOADED: 'v1_main_page_loaded',
  V2_MAIN_PAGE_LOADED: 'v2_main_page_loaded',
  V1_WITHDRAWAL_CLICK: 'v1_withdrawal_click',
  V2_WITHDRAWAL_CLICK: 'v2_withdrawal_click',
  V1_PAYMENT_PAGE_LOADED: 'v1_payment_page_loaded',
  V2_PAYMENT_PAGE_LOADED: 'v2_payment_page_loaded',
  V1_STATUS_PAGE_APPEARED: 'v1_status_page_appeared',
  V2_STATUS_PAGE_APPEARED: 'v2_status_page_appeared',
  V1_AUTHENTICATION_START: 'v1_authentication_start',
  V2_AUTHENTICATION_START: 'v2_authentication_start',
  V1_AUTHENTICATION_FAILED: 'v1_authentication_failed',
  V2_AUTHENTICATION_FAILED: 'v2_authentication_failed',
  V1_AUTHENTICATION_SUCCESS: 'v1_authentication_success',
  V2_AUTHENTICATION_SUCCESS: 'v2_authentication_success',
  V1_PAYMENT_METHOD_RESPONSE_FAILED: 'v1_payment_method_response_failed',
  V2_PAYMENT_METHOD_RESPONSE_FAILED: 'v2_payment_method_response_failed',
  V1_PAYMENT_METHOD_RESPONSE_SUCCESS: 'v1_payment_method_response_success',
  V2_PAYMENT_METHOD_RESPONSE_SUCCESS: 'v2_payment_method_response_success',
  V1_ADDITIONAL_FIELDS_VALIDATION_ERROR:
    'v1_additional_fields_validation_error',
  V2_ADDITIONAL_FIELDS_VALIDATION_ERROR:
    'v2_additional_fields_validation_error',
  V2_ADDITIONAL_FIELDS_BLOCK_WAS_OPENED_BY_USER:
    'v2_additional_fields_block_was_opened_by_user',
  V1_ADDITIONAL_FIELDS_BLOCK_WAS_OPENED_BY_USER:
    'v1_additional_fields_block_was_opened_by_user',
};
