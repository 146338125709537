import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';

import { ENV } from '@core/constants';
import { handleRejectSentryErrors } from '@core/loggers/utils';
import {
  addBreadcrumb,
  captureException,
  createReduxEnhancer,
  ErrorBoundary,
  init,
  reactErrorHandler,
  reactRouterV7BrowserTracingIntegration,
  wrapCreateBrowserRouterV7,
} from '@sentry/react';

const CashierSentry = {
  init,
  addBreadcrumb,
  ErrorBoundary,
  captureException,
  reactErrorHandler,
  createReduxEnhancer,
  wrapCreateBrowserRouterV7,
  reactRouterV7BrowserTracingIntegration,
};

export default CashierSentry;

export const initializeSentry = () => {
  const isSentryEnabled = ['prod', 'test'].includes(ENV.VITE_ENVIRONMENT);

  CashierSentry.init({
    tracesSampleRate: 0, // Capture 100% of the transactions, reduce in production!
    dsn: ENV.VITE_SENTRY_DSN,
    enabled: isSentryEnabled,
    beforeSend: handleRejectSentryErrors,
    environment: ENV.VITE_SENTRY_ENVIRONMENT,
    tracePropagationTargets: [ENV.VITE_CASHIER_API_URL],
    denyUrls: [
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /webkit-masked-url:\//i,
    ],
    integrations: [
      CashierSentry.reactRouterV7BrowserTracingIntegration({
        useEffect,
        useLocation,
        matchRoutes,
        useNavigationType,
        createRoutesFromChildren,
      }),
    ],
  });
};
