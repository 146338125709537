import { IEmbeddedPageProperties } from '@core/types';
import { createAction, createReducer } from '@reduxjs/toolkit';

interface IVerificationPageSettings {
  code: string;
  verificationCodeAttempts: number;
  verificationCodeInvalid: boolean;
  verificationData: IEmbeddedPageProperties['VERIFICATION'];
}

const initialState: IVerificationPageSettings = {
  code: '',
  verificationCodeAttempts: 0,
  verificationCodeInvalid: false,
  verificationData: {
    value: '',
    type: 'EMAIL',
  },
};

export const setVerificationError = createAction<boolean>(
  'verificationPageSettings/verificationError'
);

export const setVerificationCodeAttempts = createAction<number>(
  'verificationPageSettings/setVerificationCodeAttempts'
);

export const setVerificationData = createAction<
  IEmbeddedPageProperties['VERIFICATION']
>('verificationPageSettings/setVerificationData');

export const setVerificationCode = createAction<string>(
  'verificationPageSettings/setVerificationCode'
);

export const verificationPageSettings = createReducer(
  initialState,
  (builder) => {
    builder.addCase(setVerificationError, (state, action) => {
      state.verificationCodeInvalid =
        action.payload ?? state.verificationCodeInvalid;
    });
    builder.addCase(setVerificationCodeAttempts, (state, action) => {
      state.verificationCodeAttempts = action.payload;
    });
    builder.addCase(setVerificationData, (state, action) => {
      state.verificationData = action.payload;
    });
    builder.addCase(setVerificationCode, (state, action) => {
      state.code = action.payload;
    });
  }
);
