import { combineReducers } from 'redux';

import { cashierApi } from '@core/api';
import { appAdditionalFieldsSettings } from '@core/store/reducers/appAdditionalFieldsSettings';
import { appConfigSettings } from '@core/store/reducers/appConfigSettings';
import { appSessionSetting } from '@core/store/reducers/appSessionSetting';
import { cryptoAppSessionSetting } from '@core/store/reducers/cryptoAppSessionSetting';
import { currentSettings } from '@core/store/reducers/currentSettings';
import { embeddedSettings } from '@core/store/reducers/embeddedSettings';
import { pciSettings } from '@core/store/reducers/pciSettings';
import { praxisHpfSettings } from '@core/store/reducers/praxisHpfSettings';
import { verificationPageSettings } from '@core/store/reducers/verificationPageSettings';

const rootReducer = combineReducers({
  pciSettings,
  currentSettings,
  embeddedSettings,
  praxisHpfSettings,
  appConfigSettings,
  appSessionSetting,
  cryptoAppSessionSetting,
  verificationPageSettings,
  appAdditionalFieldsSettings,
  [cashierApi.reducerPath]: cashierApi.reducer,
});

export default rootReducer;
